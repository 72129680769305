import { useCallback } from "react";
import { SdbProject } from "@custom-types/project-types";
import { FilesInfo } from "@hooks/data-management/use-on-check-any-files";
import { useOnUploadBlinkFiles } from "@hooks/data-management/use-on-upload-files-blink";
import { useOnUploadPhotogrammetryFiles } from "@hooks/data-management/use-on-upload-files-photogrammetry";
import { UploadSettings } from "@hooks/data-management/use-upload-multiple-scans";

export type ReturnFunctionOnUploadFiles = (
  /** Data provided by the associated file validation hook, e.g. useOnCheckBlinkFiles. */
  info: FilesInfo,
  uploadSettings?: UploadSettings,
) => Promise<boolean>;

/**
 * Hook for handling file upload for both Blink and Photogrammetry files.
 * Returns true on success, false if some kind of error happened.
 * @param project The project to upload the files to.
 */
export function useOnUploadFiles(project: SdbProject): ReturnFunctionOnUploadFiles {
  const onUploadBlinkFiles = useOnUploadBlinkFiles(project);
  const onUploadPhotogrammetryFiles = useOnUploadPhotogrammetryFiles(project);

  return useCallback(
    async (info, uploadSettings): Promise<boolean> => {
      const isBlink = "lsDataV2" in info;

      if (isBlink) {
        return onUploadBlinkFiles(info, uploadSettings);
      } else {
        return onUploadPhotogrammetryFiles(info, uploadSettings);
      }
    },
    [onUploadBlinkFiles, onUploadPhotogrammetryFiles]
  );
}
